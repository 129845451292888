<script>
import 'vue3-carousel/dist/carousel.css'
// import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default{
    props:{
        landing: Object
    },
    components: {
        // Carousel,
        // Slide,
        // Navigation
    },
    data(){
        return{
            itemsToShow : 3.5,
            assetURL : process.env.VUE_APP_API_ASSET_URL
        };
    },
    mounted() {
        this.adjustItemsToShow();
        window.addEventListener('resize', this.adjustItemsToShow);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.adjustItemsToShow);
    },
    methods: {
        adjustItemsToShow() {
        const width = window.innerWidth;
        if (width < 600) {
            this.itemsToShow = 1.5;
        } else if (width < 960) {
            this.itemsToShow = 2.5;
        } else {
            this.itemsToShow = 3.5; // Default number of items to show
        }
        },
    },
}
</script>

<template>
    <section id="content">
        <div id="about" class="content-wrap-top">
            <div class="container">
                <div class="mx-auto center wow fadeInUp animated" data-wow-delay="0.3s">
                    <h5 class="subtitle">About EMDETEC</h5>
                    <h2 class="title">Bolt Tightening Specialist</h2>
                    <p class="description"> {{ landing.about.description }}</p>
                </div>
            </div>

            <div class="row align-items-stretch align-content-stretch g-0 topmargin" >
                <div class="col-md-6 order-md-2 features-bg" :style="{'background-image': 'url(' + assetURL  + landing.feature.image_url + ')'}"></div>
                    <div class="col-md-6 bg-color dark order-md-1">
                        <div class="section-features wow fadeInLeft animated" data-wow-delay="0.3s">
                            <template v-for="(item, index) in landing.feature.items" v-bind:key="index">
                                <h2 class="title">{{item.title}}</h2>
                                <p class="description light" :class=" (index!=landing.feature.items.length-1) ? 'mb-5' : 'mb-0'"> {{item.description}}</p>
                            </template>
                    </div>
                </div>
            </div>
        </div>

        <div id="products" class="content-wrap">
            <div class="container">
                <template v-for="(item, index) in landing.product.items" v-bind:key="index">
                    <div data-wow-offset="50" :class="'row flex-responsive ' + ((index%2==0) ? 'right ' : 'left') + ((index == landing.product.items.length-1) ? 'bottom ' : '')" >
                        <div class="col-md-5 col-sm-12 col-xs-12 align-items-center wow fadeInRight animated" data-wow-delay="0.3s">
                            <div class="img-container">
                                <img :src="(assetURL + item.image_url)" alt=""> 
                            </div>
                        </div>
                        <div class="col-md-7 col-sm-12 wow fadeInLeft animated" data-wow-delay="0.3s">
                            <div class="product-content-right">
                                <div class="title">{{item.title}}</div>
                                <div class="description">{{ item.description }}</div>
                                <div class="button-main dark"><a :href="(item.file_url)"></a> {{item.cta_text}}</div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <div id="ads-banner" class="content-wrap wow fadeInUp animated" data-wow-delay="0.3s">
            <div class="container">
                <div class="ads-banner-img" :style="{'background-image': 'url(' + assetURL + landing.ads.image_url + ')'}">
                    <div class="description light" style="font-weight: bold;">{{ landing.ads.description }}</div>
                    <a class="button-main dark" :href="(landing.ads.button_url)" target="_blank">{{landing.ads.button_text}}</a>
                </div>
            </div>
        </div>

        <div id="certificate" class="content-wrap wow fadeInUp animated" data-wow-delay="0.3s">
            <div class="container">
                <div class="row flex-responsive certificate">   
                    <div class="col-md-7 col-sm-12">
                        <div class="certificate-content">
                            <div class="title">Certification</div>
                            <div class="description">{{landing.certification.description}}</div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-12 col-xs-12 align-items-center">
                        <div class="img-container">
                            <img :src="(assetURL + landing.certification.image_url)" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div id="calculator">
            <div class="row align-items-stretch align-content-stretch g-0 topmargin" >
                <!-- <div class="button-main dark" style="text-align: center;"><a></a> Experience Our Calculator</div> -->
                <div class="bg-color dark order-md-1" style="text-align: center;">
                    <div class="wow fadeInLeft animated py-4" data-wow-delay="0.3s">
                        <h2 class="description light">Experience The World First Bolt Tightening Calculator</h2>
                        <a href="https://bolt.pttjc.com" target="_blank"><div class="button-main dark" style="text-align: center;">Visit Calculator</div></a>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div id="clients" class="content-wrap-bot">
            <div class="container">
                <div class="mx-auto center">
                    <h5 class="subtitle">Industry</h5>
                    <h2 class="title">Clients / Go to Market</h2>
                    <p class="description">{{landing.client.description}}</p>
                </div>
                <Carousel  :items-to-show="itemsToShow" :wrap-around="true" :transition="500">
                    <Slide v-for="(item, index) in landing.client.items" v-bind:key="index">
                        <div class="client-img-container">
                            <img :src="(item.image_url)" class="client-img" alt="Image 1">
                        </div>
                    </Slide>
                    <template #addons>
                        <Navigation />
                    </template>
                </Carousel>
            </div>
        </div> -->
    </section>
</template>

<style scoped>
.carousel__slide {
  padding: 10px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(20deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(20deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>