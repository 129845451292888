
<script>
export default {
	props: {
		navbar: Object
	},
	data() {
		return {
			assetURL: process.env.VUE_APP_API_ASSET_URL
		};
	}
}
</script>

<template>
	<!-- Header
		============================================= -->
	<header id="header" class="full-header dark no-sticky">
		<div id="header-wrap">
			<div class="container">
				<div class="header-row">
					<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
						<div class="container d-flex justify-content-between">
							<div class="border-end-0">
							<a href="/" class="standard-logo">
								<!-- <img src="/emdetec-logo.png" style="height: 55px;"> -->
								<img :src="( assetURL + navbar.icon.image_url)" style="height: 55px;">
							</a>
							</div>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
								aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
								<span class="navbar-toggler-icon"></span>
							</button>
							<div class="collapse navbar-collapse justify-content-end" id="navbarNav">
								<ul class="navbar-nav ml-auto">
									<li class="nav-item active">
										<a class="menu-link" href="#about">About</a>
									</li>
									<li class="nav-item">
										<a class="menu-link" href="#products">Products</a>
									</li>
									<li class="nav-item">
										<a class="menu-link" href="#certificate">Services</a>
									</li>
									<li class="nav-item d-flex align-items-center">
										<a class="button-main dark" href="tel:+62217562929">Call Us</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>

					<!-- Logo
						============================================= -->
					<!-- <div id="logo" class="border-end-0">
							<a href="/" class="standard-logo" data-dark-logo="images/logo-dark.png"><img :src="( assetURL + navbar.icon.image_url)" ></a>
						</div> -->
					<!-- #logo end -->

					<!-- <div id="primary-menu-trigger">
							<svg class="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
						</div> -->

					<!-- Primary Navigation
						============================================= -->
					<!-- <nav id="nav-bar" class="primary-menu not-dark with-arrows">
							<ul class="me-0 border-0 menu-container">
								<li class="menu-item"><a class="menu-link" href="#about"><div>About</div></a></li>
								<li class="menu-item"><a class="menu-link" href="#products"><div>Products</div></a></li>
								<li class="menu-item"><a class="menu-link" href="#certificate"><div>Certification</div></a></li>
								<li class="menu-item cta"><a class="button-main dark" href="tel:+62217562929"><div>Call Us</div></a></li>
							</ul>
						</nav> -->
					<!-- #primary-menu end -->

				</div>
			</div>
		</div>
		<div class="header-wrap-clone"></div>
	</header><!-- #header end -->

	<!-- Slider
		============================================= -->
	<section id="hero-banner">
		<div class="banner-ads" :style="{ 'background-image': 'url(' + assetURL + navbar.banner.image_url + ')' }">
			<div class="banner-text">The Best Tools For Bolt Tightening</div>
		</div>
	</section><!-- #slider end -->
</template>
