<script>
import NavBar from "/src/components/NavBar.vue";
import ContentsComp from "/src/components/ContentsComp.vue";
import FooterComp from "/src/components/FooterComp.vue"
export default{
    components:{
        NavBar,
        ContentsComp,
        FooterComp
    },
    data(){
        return{
            apiURL: process.env.VUE_APP_API_URL,
            landingpage: {}
        };
    },

    methods:{
        async getData(){
            await this.axios.get(this.apiURL)
            .then(res => {
                this.landingpage = res.data;
            });
        }
    },

    created(){
        console.log(this.apiURL);
        this.getData();
    }
}
</script>

<template>
    <div id="wrapper" class="clearfix">
        <NavBar :navbar="landingpage"/>
        <ContentsComp :landing="landingpage"/>
        <FooterComp :footer="landingpage.footer"/>
    </div>
</template>