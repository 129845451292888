<script>
export default{
    props:{
        footer: Object
    },
}
</script>

<template>
    <footer id="footer" class="">
        <div class="container">
            <div class="footer-widgets-wrap pt-2 pb-5">
                <div class="row mx-auto  align-items-end">
                    <div class="col-md-8">
                            <div class="widget widget_links">
                                <h2 class="title light">Contact Us</h2>
                                
                                <div class="footer-desc">
                                    <h5 class="subtitle light">Address</h5>
                                    <p class="description light">{{footer.address}}</p>
                                </div>
                                <div class="footer-desc">
                                    <h5 class="subtitle light">Phone</h5>
                                    <a class="description light" :href="(footer.whatsapp_url)" target="_blank" >{{footer.whatsapp}}</a>
                                </div>
                                <div class="footer-desc">
                                    <h5 class="subtitle light">Email</h5>
                                    <p class="description light">{{footer.email}}</p>
                                </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="entry-image">
                            <!-- TODO: Albert was here -->
                            <div v-html="footer.maps_url"></div>
                            <!-- <img src="https://dummyimage.com/600x400/808080/fff" alt="Image 3"> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer><!-- #footer end -->
</template>